/* eslint-disable import/no-anonymous-default-export */
import { put, call, takeLeading } from "redux-saga/effects";
import { toast } from "react-toastify";
import url from "../../api";
import {
  getdispatcher,
  getdispatcherError,
  getdispatcherSuccess,
  getNotifications,
  getNotificationsSuccess,
  getNoficationsError,
  updateNotifications,
  updateNotificationsSuccess,
  updateNoficationsError,
  updatedispatcher,
  updatedispatcherError,
  updatedispatcherSuccess,
} from "../actions/dispatcherActions";
import { storeAuthToken } from "../actions/authActions";
import { getAuthToken } from "./authSaga";

function* getdispatcherSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
  
    const { user_id } = action.payload;
    const response: any = yield call(url.get, `/dispatchers/${user_id}`);
    const { data } = response.data;
    yield put(getdispatcherSuccess(data));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getdispatcherError(error));
    }
    const {
      data: { message },
    } = error.response;
   
    yield put(getdispatcherError(message));
  }
}

function* updatedispatcherSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { user_id, dispatchData } = action.payload;
   
    const response: any = yield call(
      url.put,
      `/dispatchers/${user_id}`,
      dispatchData
    );
    const { data } = response.data;
    toast.success("Dispatcher updated sucessfully");
    getdispatcherSaga(action)
    yield put(updatedispatcherSuccess(data));
    yield put(getdispatcher({ user_id }));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(updatedispatcherError(error));
    }

    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(updatedispatcherError(message));
  }
}

function* getNotificationsSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );

    const _dispatcher: any = yield call(url.get, `/dispatchers/${_authToken.user_id}`);
    const { precinct_id, precinct_name } = _dispatcher.data.data.dispatcher;

    const response: any =
      precinct_name?.split(" ")[0] === "Lagos"
        ? yield call(url.get, `/notifications?entity=dispatcher&limit=0`)
        : yield call(url.get, `/notifications?entity=dispatcher&limit=0&precinct_id=${precinct_id}`);
    
    const { data } = response.data;
    yield put(getNotificationsSuccess(data.notifications));
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(getNoficationsError(error));
    }
    const message = error?.response?.data?.message;

    yield put(getNoficationsError(message));
  }
}

function* updatenotificationSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken);
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    );
    const { case_id } = action.payload;
  
    const response: any = yield call(
      url.put,
      `/notifications/${case_id}?entity=dispatcher`,
    );
    const { data } = response.data;
    toast.success("Dispatcher updated sucessfully");
    yield put(updateNotificationsSuccess(data));
    getNotificationsSaga(case_id);
  } catch (error: Error | any) {
    if (error.toString() === "Error: Network Error") {
      console.log("Please check your network connection and try again");
      yield put(updateNoficationsError(error));
    }

    const {
      data: { message },
    } = error.response;
    toast.error(message);
    yield put(updatedispatcherError(message));
  }
}


function* hospitalSaga() {
  yield takeLeading(getdispatcher, getdispatcherSaga);
  yield takeLeading(updatedispatcher, updatedispatcherSaga);
  yield takeLeading(getNotifications, getNotificationsSaga);
  yield takeLeading(updateNotifications, updatenotificationSaga);
}

export default hospitalSaga;
