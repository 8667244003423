/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Suspense, useEffect } from "react";
import { nanoid } from "nanoid";
import { Switch } from "react-router-dom";
import { routes } from "../routes";
import Loader from "../components/common/spinner";
import { AuthProvider } from "../utils/contexts/authDetails";
import { ToastContainer } from "react-toastify";
import { MessagePayload, onMessage } from "firebase/messaging";
import { messaging } from "../utils/FirebaseConfig";
import { handleGetFirebaseToken } from "../api";
import CustomToast from "../components/CustomToast";
import toast from "react-hot-toast";

interface NotificationType {
  notification?: {
    title: String;
    body: String;
  };
  data?: {
    type?: string;
    notification_id?: string;
    id?: string;
    group?: string;
    precinct_id?: string;
  };
}

const App: React.FC = () => {
  const [id] = useState(nanoid);

  // This is self invoking function that listens for the notification
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onMessageListener = (async () => {
    const messagingResolve = await messaging;
    if (messagingResolve) {
      onMessage(messagingResolve, (payload: MessagePayload) => {
        const convertPayload: NotificationType = payload as object;
        toast.custom(
          (t) => (
            <CustomToast
              title={convertPayload?.notification?.title}
              body={convertPayload?.notification?.body}
              data={convertPayload?.data}
              t={t}
            />
          ),
          { duration: 60000 }
        );
      });
    }
  })();

  // Need this to handle FCM token generation when a user manually blocks or allows notification
  useEffect(() => {
    if (
      "Notification" in window &&
      window.Notification?.permission === "granted"
    ) {
      const firebasePermissionToken = localStorage.getItem(
        "firebasePermissionToken"
      );
      if (!firebasePermissionToken) {
        handleGetFirebaseToken();
      }
    }
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <AuthProvider>
        {"Notification" in window && Notification.permission !== "granted" && (
          <div className="flex justify-center">
            <div>
              <span>The app needs permission to </span>
              <span
                className="text-primary cursor-pointer"
                onClick={handleGetFirebaseToken}
              >
                enable push notifications.
              </span>
            </div>
          </div>
        )}
        <ToastContainer
          theme="colored"
          position="bottom-right"
          autoClose={5000}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
        />
        <Switch>
          {routes.map(({ path, component, RouteType }) => {
            return (
              <RouteType key={id} path={path} exact component={component} />
            );
          })}
        </Switch>
      </AuthProvider>
    </Suspense>
  );
};

export default App;
